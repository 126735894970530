import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/dist/index.css';
import * as ElIconModules from '@element-plus/icons-vue';
// @ts-ignore
import $ from 'jquery';
// import jquery from 'jquery';

const app = createApp(App);
for (const [key, component] of Object.entries(ElIconModules)) {
    app.component(key, component)
}

app.use(store).use(router).use(Element,{locale: zhCn}).mount('#app')
