import { createStore } from 'vuex'

export default createStore({
  state: {
    userId: '',
    token: '',
    loginTime: '',
    isLogin: false
  },
  getters: {
  },
  mutations: {
    getUserId(state,userId){
      state.userId = userId;
    },
    getToken(state,token){
      state.token = token;
    },
    getLoginTime(state,loginTime){
      state.loginTime = loginTime;
    },
    getLogin(state){
      state.isLogin = true;
    }
  },
  actions: {
  },
  modules: {
  }
})
