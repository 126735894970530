import axios from 'axios';
import Qs from 'qs';
import apiAll from './api';

let base = 'https://qyyz.ubigger.com/mt/s/';
let bases = 'https://gpt-521.com/mt/s/';
let basess = 'https://qyyz-test.ubigger.com/mt/s/';

let config = {
    transformRequest: [function (data) {
        return Qs.stringify(data)
    }],
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}
export const getUserInfoApi = params => { return axios.post(`${base}WzUserModel/getUserInfo`, params,config).then(res => res.data); };//用户详情
export const getAllAdTaskApi = params => { return axios.post(`${base}WzAdDefineModel/getAllAdTask`, params,config).then(res => res.data); };//广告任务
export const getShowOrderApi = params => { return axios.post(`${base}WzUserOrderModel/getShowOrder`, params,config).then(res => res.data); };//排行榜
export const getHistoryApi = params => { return axios.post(`${base}WzWatchHistoryModel/getHistory`, params,config).then(res => res.data); };//任务历史
export const beginWatchAdApi = params => { return axios.post(`${base}WzWatchHistoryModel/beginWatchAd`, params,config).then(res => res.data); };//开始任务
export const getTaskLinkApi = params => { return axios.post(`${base}WzAdDefineModel/getTaskLink`, params,config).then(res => res.data); };//获取跳转链接

export const getSignApi = params => { return axios.post(`${base}WxServiceModel/getSign`, params,config).then(res => res.data); };//获取微信信息
export const getOpenIdApi = params => { return axios.post(`${base}WxServiceModel/getOpenIdByCode`, params,config).then(res => res.data); };//获取openId
export const getReferInfoApi = params => { return axios.post(`${base}WzUserModel/getReferInfo`, params,config).then(res => res.data); };//查询邀请历史
export const getReferListApi = params => { return axios.post(`${base}WzPayUserModel/getReferList`, params,config).then(res => res.data); };//获取openId
export const addReferUserApi = params => { return axios.post(`${base}WzUserModel/addReferUser`, params,config).then(res => res.data); };//添加推荐人
export const getCashPayTypeApi = params => { return axios.post(`${base}WzUserCashModel/getCashPayType `, params,config).then(res => res.data); };//查询付款类型枚举
export const doCashPayApi = params => { return axios.post(`${base}WzUserCashModel/doCashPay `, params,config).then(res => res.data); };//提现
export const getCashPayHistoryApi = params => { return axios.post(`${base}WzUserCashModel/getCashPayHistory `, params,config).then(res => res.data); };//查询用户提现流水